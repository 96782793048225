<template>
  <div class="home">
    <div v-if="content.images" class="flex flex-wrap justify-center items-center h-screen">
      <randomImage :image="content.images[Math.floor(Math.random() * content.images.length)]" />
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import randomImage from '@/components/randomImage.vue'

export default {
  name: 'Home',
  components: {
    randomImage
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  apollo: {
    content: {
      query: gql`
        {
          content(where: {id: "ckwlco6k088c70c25zxkls3ur"}) {
            id
            images {
              id
              url
              caption
            }
            about {
              html
            }
            contact {
              html
            }
          }          
        }
      `
    }
  }
}
</script>

<style>

</style>
