<template>
  <div class="w-full md:w-6/12 lg:w-6/12 xl:w-5/12 max-w-2xl p-0 lg:p-0 ">
    <div class="relative" style="padding-bottom: 136.333%;">
      <div class="bg-cover bg-no-repeat bg-center inset-0 absolute" v-bind:style="{ backgroundImage: 'url(' + image.url + ')' }"></div>
      </div>
      <span class="">{{image.caption}}</span>  
  </div>
</template>

<script>
export default {
  name: 'randomImage',
  props: {
    image: Object
  }
}
</script>


<style>

.sh {
      /* min-height: calc(44.44444vw + 0.125rem); */
}

</style>
