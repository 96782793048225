<template>
  <div class="contact">
    <div class="px-5 flex flex-wrap items-center justify-center h-screen">
      <div class="whitespace-pre-line text-center p-rules" v-html="content.contact.html"></div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: 'Contact',
  components: {
    
  },
  mounted () {
    window.scrollTo(0, 0)
  },    
  apollo: {
    content: {
      query: gql`
        {
          content(where: {id: "ckwlco6k088c70c25zxkls3ur"}) {
            id
            images {
              url
              caption
            }
            about {
              html
            }
            contact {
              html
            }
          }          
        }
      `
    }
  }
}
</script>

<style>

</style>
